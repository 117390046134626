import React, { FunctionComponent } from "react"
import { GoDeeper as GoDeeperProps } from "../../support-types"
import styles from "./go-deeper.module.scss"
import { CardLearn } from "@components/card/card-learn"
import { Markdown } from "@components/markdown/markdown"

export const GoDeeper: FunctionComponent<GoDeeperProps> = ({
  title,
  body,
  cards
}: GoDeeperProps) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <Markdown className={styles.title} content={title} />
      <div className={styles.body}>{body}</div>
    </div>
    <div className={styles.cards}>
      {cards.map((card, index) => (
        <CardLearn
          key={index}
          title={card.title}
          body={card.body}
          callout={card.callout}
          imageFluid={{
            src: card.image,
            aspectRatio: 2,
            srcSet: "",
            sizes: ""
          }}
          link={card.link}
          className={styles.card}
        />
      ))}
    </div>
  </div>
)
