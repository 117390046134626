import React, { FunctionComponent } from "react"
import { TheologicalFeatures as TheologicalFeaturesProps } from "../../support-types"
import styles from "./theological-features.module.scss"
import { Markdown } from "@components/markdown/markdown"

export const TheologicalFeatures: FunctionComponent<
  TheologicalFeaturesProps
> = ({ image, items }) => (
  <div className={styles.featuredTestimonies}>
    <div className={styles.content}>
      <div className={styles.image}>
        <img data-src={image} alt="" className="lazyload" />
      </div>
      <div className={styles.text}>
        {items.map((item) => (
          <div className={styles.testimony} key={item.name}>
            <Markdown className={styles.name} content={item.name} />
            <Markdown className={styles.body} content={item.body} />
          </div>
        ))}
      </div>
    </div>
  </div>
)
