import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import styles from "./card.module.scss"
import { Link } from "@components/link/link"

export type CardBackground = "white" | "smoke" | "cream" | "transparent"

export type CardVariant = "shadow" | "border" | "flat"

export type CardOrientation = "horizontal" | "vertical"

export type CardSize = "xs" | "sm" | "md" | "lg" | "xl"

export interface CardProps {
  background?: CardBackground
  className?: string
  link?: string
  alt?: string
  tag?: keyof JSX.IntrinsicElements
  variant?: CardVariant
  onClick?: MouseEventHandler
}

export const Card: FunctionComponent<CardProps> = ({
  background = "white",
  children,
  className,
  tag: Tag = "div",
  variant = "shadow",
  link,
  alt,
  onClick
}) =>
  link ? (
    <Link
      to={link}
      alt={alt}
      className={classNames(
        styles.card,
        styles[background],
        styles[variant],
        Boolean(onClick) && styles.interactive,
        className
      )}
    >
      {children}
    </Link>
  ) : (
    <Tag
      className={classNames(
        styles.card,
        styles[background],
        styles[variant],
        Boolean(onClick) && styles.interactive,
        className
      )}
      onClick={onClick}
    >
      {children}
    </Tag>
  )
