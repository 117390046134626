import React, { FunctionComponent } from "react"
import classNames from "classnames"
import Image, { FluidObject } from "gatsby-image"
import { Link } from "../link/link"
import { Card, CardSize } from "./card"
import styles from "./card-learn.module.scss"
import { Icon } from "@components/icon/icon"
import { Markdown } from "@components/markdown/markdown"

export type CardLearnSize = Exclude<CardSize, "xl"> | "fluid"

export interface CardLearnProps {
  body: string
  callout?: string
  className?: string
  imageFluid: FluidObject
  link: string
  onClick?: (link: string) => void
  size?: CardLearnSize
  title: string
}

export const CardLearn: FunctionComponent<CardLearnProps> = ({
  body,
  callout = "Explore",
  className,
  imageFluid,
  link,
  size = "md",
  title
}) => {
  const reactMarkdownProps = {
    disallowedElements: ["p", "h1", "h2", "h3", "h4", "h5", "h6"],
    unwrapDisallowed: true,
    className: styles.body
  }

  return (
    <Card
      className={classNames(styles.cardLearn, styles[size], className)}
      variant="shadow"
      link={link}
      alt={title}
    >
      <div className={styles.image}>
        {imageFluid && <Image fluid={imageFluid} alt={title} />}
      </div>
      <div className={styles.content}>
        <div className={styles.textContent}>
          <div className={styles.title}>
            {title}
            {link?.includes("https") && (
              <Icon
                className={styles.externalLinkIcon}
                variant="12-external-link"
              />
            )}
          </div>
          <Markdown content={body} {...reactMarkdownProps} />
        </div>
        <div className={styles.callout}>
          <Link to={link}>{callout}</Link>
        </div>
      </div>
    </Card>
  )
}
