import React, { FunctionComponent } from "react"
import classNames from "classnames"
import styles from "./about-author.module.scss"
import { Markdown } from "@components/markdown/markdown"
import { Link } from "@components/link/link"

export type AboutAuthorVariant = "green" | "white"

export interface AboutAuthor {
  name: string
  info: string
  url: string
  image: string
}

export interface AboutAuthorDetailsProps {
  details: AboutAuthor
  variant?: AboutAuthorVariant
  className?: string
}

export const AboutAuthorDetails: FunctionComponent<AboutAuthorDetailsProps> = ({
  details,
  variant = "green",
  className
}) => (
  <div className={classNames(styles.founder, styles[variant], className)}>
    <div className={styles.content}>
      <div className={styles.image}>
        <Link to="https://learn.ligonier.org/teachers/rc-sproul">
          <img data-src={details.image} alt="" className="lazyload" />
        </Link>
      </div>
      <div className={styles.text}>
        <Link to="https://learn.ligonier.org/teachers/rc-sproul">
          <Markdown className={styles.name} content={details.name} />
        </Link>
        <Markdown className={styles.info} content={details.info} />
      </div>
    </div>
  </div>
)
