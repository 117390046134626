import React, { FunctionComponent } from "react"
import { Button } from "../../../../components/button/button"
import { ProductCTA as ProductCTAProps } from "../../support-types"
import styles from "./product-cta.module.scss"
import { Markdown } from "@components/markdown/markdown"

export const ProductCTA: FunctionComponent<ProductCTAProps> = ({
  title,
  image,
  subtitle,
  body,
  primaryButtonText,
  primaryButtonUrl
}) => (
  <div className={styles.productCTA}>
    <div className={styles.content}>
      <div className={styles.image}>
        <img data-src={image} alt="" className="lazyload" />
      </div>
      <div className={styles.text}>
        <Markdown className={styles.subtitle} content={subtitle} />
        <Markdown className={styles.title} content={title} />
        <Markdown className={styles.body} content={body} />
        <div className={styles.buttons}>
          <Button
            size="md"
            variant="primary"
            className={styles.button}
            to={primaryButtonUrl}
          >
            {primaryButtonText}
          </Button>
        </div>
      </div>
    </div>
  </div>
)
