import React, { FunctionComponent } from "react"
import { Button } from "../../../../components/button/button"
import { TeachingSeriesCTA as TeachingSeriesCTAProps } from "../../support-types"
import styles from "./teaching-series-cta.module.scss"
import { Markdown } from "@components/markdown/markdown"

export const TeachingSeriesCTA: FunctionComponent<TeachingSeriesCTAProps> = ({
  image,
  title,
  subtitle,
  body,
  buttonText,
  buttonUrl
}) => (
  <div className={styles.teachingSeriesCTA}>
    <div className={styles.header}>
      <p className={styles.title}>{title}</p>
    </div>
    <div className={styles.content}>
      <div className={styles.image}>
        <img data-src={image} alt="" className="lazyload" />
      </div>
      <div className={styles.text}>
        <p className={styles.subtitle}>{subtitle}</p>
        <Markdown className={styles.body} content={body} />
        <Button
          size="md"
          variant="primary"
          className={styles.button}
          to={buttonUrl}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  </div>
)
